import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import SiteMetadata from "../components/SiteMetadata";
import "./main.scss";

const Layout = ({ children }) => {
  const { title, description } = SiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="laika, trust center" content="keywords"></meta>
        <meta property="og:url" content="https://laika.com/"></meta>
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
