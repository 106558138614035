import Layout from "./src/layouts/main";
import React from "react";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <>{element}</>;
}
