import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tickIcon from "../images/tick_icon.png";
import wwLogo from "../images/wwLogo.svg";
import SiteMetadata from "./SiteMetadata";

function Footer() {
  const { title } = SiteMetadata();
  return (
    <div className="wirewheel-footer">
      <Container>
        <Row>
          <Col className="text-center text-white p-4">
            <p className="m-0">
              <a
                href="https://www.laika.com/privacy-policy"
                className="footer-link"
              >
                Privacy
              </a>
            </p>
          </Col>
          <Col className="text-center text-white p-4">
            <a
              href="https://www.laika.com/gpc-confirmation"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Your Privacy Choices
            </a>{" "}
            <img src={tickIcon} alt="yourPrivacyChoices" />
          </Col>
        </Row>
        <Row className="text-center text-white">
          <p>
            © <span>{new Date().getFullYear()}</span> {title}.
          </p>

          <a
            className="mb-3 text-white"
            href="https://wirewheel.io/"
            target="_blank"
            rel="noreferrer"
          >
            Powered by
            <img
              alt="wirewheel logo"
              src={wwLogo}
              className="ms-2"
              width="120px"
            />
          </a>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
